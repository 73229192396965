/* AuthModal.css */

.auth-modal {
    position: fixed;
    z-index: 1000; /* High z-index to be on top */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5); /* Black with opacity */
}

.auth-modal-content {
  position: relative; /* Needed for absolute positioning of children */
  background-color: #fefefe;
  margin: 10% auto; /* Centered in the viewport */
  padding: 30px;
  border: 1px solid #888;
  max-width: 500px;
  min-height: 380px;
}

  .register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Take up all available height */
    width: 100%; /* Take up all available width */
  }

  .close {
    position: absolute;
    top: 6px; /* Adjust as needed */
    right: 14px; /* Adjust as needed */
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }

.close:hover,
.close:focus {
    color: black;
    cursor: pointer;
}


  