@font-face {
    font-family: 'Markazi Text Bold';
    src: url('../fonts/MarkaziText-VariableFont_wght.ttf') format('truetype');
  }

.blog-post {
    width: 90%;
    max-width: 1000px;  
    margin-left: auto;
    margin-right: auto;   
    box-sizing: border-box; 
    text-align: left;
    margin-bottom: 7%;
}

.feature-image {
    width: 100%;      
    max-width: 950px; 
    max-height: 300px;  
    margin-top: 45px;    
    margin-bottom: 40px;
    display: block;
    border-radius: 8px; 
    object-fit: cover;
    box-shadow: 0px 0px 4px #00000040;
}

.blog-body {
    width: 92%;
    max-width: 725px;  
    margin-left: auto;
    margin-right: auto;   
    box-sizing: border-box; 
    text-align: left;
}

.blog-post h1 {
    font-size: 48px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    line-height: 1;
}

.blog-post h2 {
    font-size: 32px;
    line-height: 1.2;
    margin-bottom: 0px;
}

.blog-post p {
    font-family: "Markazi Text Bold", Helvetica;
    margin-bottom: 1.5rem;
    margin-top: .5rem;
    font-size: 26px;
    color: #434a50;
}

figure.kg-card.kg-image-card {
    text-align: center; 
    margin-left: 0px;
    margin-right: 0px;
}

figure.kg-card.kg-image-card img.kg-image {
    max-width: 100%; 
    height: auto;
}