/* CustomButton.css */
.custom-button {
    display: inline-block; /* or 'inline-flex' if you want to use flex properties inside */
    padding: 10px 20px;
    min-width: 150px; /* Adjust as needed */
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    text-transform: none;
    margin-right: 10px; /* for spacing between buttons */
    white-space: nowrap;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between; /* or 'flex-start' if you want them to stick together */
    gap: 10px; /* This creates space between your buttons */
  }
  
  .custom-button:hover {
    font-size: 18px;
  }
  
  .custom-button.disabled {
    cursor: default;
    opacity: 0.7;
  }
  