.chart-container {
  height: 35vh; /* Or any other suitable height */
  box-shadow: 0px 1px 2px rgb(134, 134, 134);
  max-width: 800px;
  margin-left: 4%;
  margin-right: 4%;
}
.heading-2 {
  margin-left: 4%; /* Adjust the value as needed */
  margin-top: 0px;
  margin-bottom: 1%;
}
.margin-left-4 {
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 4%;
}
.checkbox-container {
  margin-left: 4%;
  margin-top: 1%;
  display: flex;
  align-items: center;
  gap: 0px;
  margin-bottom: 6%;
}
.tight-checkbox {
  margin-right: 8px; /* Adjust this value as needed */
}
.left-right-4 {
  margin-left: 4%;
  margin-right: 4%;
}
/* Default styles (stacked layout) */
.leftDiv,
.rightDiv {
  width: 100%;
}

/* Side-by-side layout for screens wider than 700px */
@media (min-width: 1180px) {
  .margin-left-4 {
    margin-left: 8%;
  }
  .heading-2 {
    margin-left: 8%;
  }
  .checkbox-container {
    margin-left: 8%;
    gap: 2%;
  }
  .chart-container {
    margin-right: 2%;
    margin-left: 8%;
    height: 44vh;
  }
  .content2 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px; /* Adjust the gap between columns as needed */
  }
  .leftDiv {
    width: 50%;
    position: sticky;
    top: 70px;
    height: auto;
    max-height: 70vh;
  }
  .rightDiv {
    width: 50%; /* Adjust if you want different ratios for columns */
  }
}
.header2 {
  width: 100%;
  /* Additional styling as needed */
}
