@font-face {
    font-family: 'Markazi Text Bold';
    src: url('../fonts/MarkaziText-VariableFont_wght.ttf') format('truetype');
  }

.privacyPolicy {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 190px);
}
  
.overlap_text_pp {
    color: #000000;
    font-family: "Markazi Text Bold", Helvetica;
    font-size: 36px;
    font-weight: 200;
    letter-spacing: 0;
    text-align: left;
    margin-left: 5%;
    margin-right: 5%;
  }

.h-2 {
    color: #000000;
    font-family: "Markazi Text Bold", Helvetica;
    font-size: 42px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: left;
    margin-left: 3%;
    margin-right: 5%;
}

  @media (max-width: 828px) {
    .overlap_text_pp {
      font-size: 28px;
    }

    .h-2 {
        font-size: 34px;
    }
  }