@font-face {
    font-family: 'Markazi Text Bold';
    src: url('../fonts/MarkaziText-VariableFont_wght.ttf') format('truetype');
  }

.Invest{
    background-color: #ffffff;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 190px);
}

.overlap_text_invest {
    color: #000000;
    font-family: "Markazi Text Bold", Helvetica;
    font-size: 36px;
    font-weight: 200;
    letter-spacing: 0;
    text-align: left;
    margin-left: 10%;
    margin-right: 10%;
  }

.grid-Container{
    background-color: #f2f4fd;
    outline: 2px solid #1350d5;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 8%;
    padding: 4%;
    padding-bottom: 2%;
    max-width: 700px;
}

@media (max-width: 828px) {
  .overlap_text_invest {
    font-size: 30px;
  }
}





  
  
  