@font-face {
  font-family: 'Markazi Text Bold';
  src: url('../fonts/MarkaziText-VariableFont_wght.ttf') format('truetype');
}

.App {
    text-align: center;
  }

.App-header {
    background-color: #ffffff;
    min-height: calc(100vh - 240px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
    margin-bottom: 50px;
  }


  
  @media (max-width: 828px) {
    .table-override{
      font-size: calc(5px + 1vmin);
    }
  }