@font-face {
  font-family: 'Markazi Text Bold';
  src: url('../fonts/MarkaziText-VariableFont_wght.ttf') format('truetype');
}

.frame {
  color: white;
  display: flex;
  flex-direction: row;
  width: 100%;
  box-shadow: 0px -2px 2px rgb(212, 212, 212);
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  }

.frame .join_investors{
    color:black;
    font-family: "Markazi Text Bold", Helvetica;
    font-size: 20px;
    font-weight: 350;
    padding: 0px;
    display: flex;
    justify-content: center;
    text-align: center;
} 

.join_investors p{
  margin-block-start: .75em;
  margin-block-end: 0;
}

.join_investors a{
  text-decoration: none;
  color: black;
}
