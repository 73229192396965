@font-face {
  font-family: 'Markazi Text Bold';
  src: url('../fonts/MarkaziText-VariableFont_wght.ttf') format('truetype');
}

.navbar {
  color: white;
  display: flex;
  flex-direction: row;
  width: 100%;
  z-index:1;
}

.container {
  background-color: white;
  border: none;
  box-shadow: 0px 1px 4px rgb(56, 56, 56);
  height: 90px;
  width: 100%;
}

.logo {
  position: absolute;
  top: 25px;
  left: 25px;
}

.logo a {
  display: block; 
}

.logo-img {
  width: 400px; 
  height: auto;
}

.navbar ul {
  display: flex;
  margin: 20px;
  padding-right: 20px;
  justify-content: flex-end;
}

.navbar li {
  margin-left: 35px;
}

.nav-link {
  font-size: 16px;
  text-decoration: none;
}

.nav-link.home,
.nav-link.blog,
.nav-link.about_link,
.nav-link.invest {
  color: black;
  font-family: 'Markazi Text Bold', Helvetica;
  font-size: 42px;
  font-weight: 550;
  letter-spacing: 0;
  line-height: normal;
}

.nav-link.invest {
  color: #1351d5;
  margin-left: auto;
}

.hamburger-menu {
  width: 35px;
  height: auto;
  position: absolute;
  top: 30px;
  right: 25px;
}

.hamburger-menu img {
  width: 100%;
  height: auto;
}

.full-screen-menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(7, 32, 84, 0.95);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.close-hamburger{
  width: 35px;
  height: auto;
  position: absolute;
  top: 30px;
  right: 25px;
  background-color: white;
}

.close-hamburger img {
  width: 100%;
  height: auto;
}

.menu-nav-links {
  color: white;
  font-family: 'Markazi Text Bold', Helvetica;
  font-size: 42px;
  font-weight: 450;
  list-style: none;
  text-decoration: none;
}

.menu-nav-links ul {
  display: flex;
  flex-direction: column;
  color: white;
  list-style: none;
  padding-left: 0;
  text-align: center;
}

.menu-nav-links ul li a {
  color: white;
  text-decoration: none;
}


@media (max-width: 828px) {

  .logo {
    position: absolute;
    top: 32.5px;
    left: 5px;
  }
  
  .logo-img {
    width: 275px;
  }

  .navbar hidden-ul {
    display: none;
  }

  .hamburger-menu {
    display:  block;
  }


}