@font-face {
    font-family: 'Markazi Text Bold';
    src: url('../fonts/MarkaziText-VariableFont_wght.ttf') format('truetype');
  }

.about {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 190px);
}

.h-1 {
    color: #000000;
    font-family: "Markazi Text Bold", Helvetica;
    font-size: 68px;
    font-weight: 500;
    margin-left: 3%;
    margin-top: 1%;
    margin-bottom: 1%;
  }

.overlap {
    background-color: #ffffff;
    box-shadow: 0px 0px 8px #00000040;
    top: 215px;
    margin-left: 7%;
    margin-right: 7%;
    margin-bottom: 9%;
  }

  .about .aris_photo {
    width: 22%; 
    min-width: 120px;
    max-width: 220px;
    height: auto;
    border: 2px solid black;
    border-radius: 50%;
    float: left;
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 7%;
    margin-bottom: 3%;
}
  
.overlap_text_about {
    color: #000000;
    font-family: "Markazi Text Bold", Helvetica;
    font-size: 36px;
    font-weight: 200;
    letter-spacing: 0;
    text-align: left;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 3.5%;
  }

  @media (max-width: 828px) {
    .overlap_text_about {
      font-size: 30px;
    }
    
  }

  @media (min-width: 828px) { 
    .about .aris_photo{
        margin-left: 50px;
        margin-right: 40px;
        margin-top: 70px;
        margin-bottom: 10px;
    }

    .overlap_text_about {
      margin-top: 50px;
    }
}