@font-face {
    font-family: 'Markazi Text Bold';
    src: url('../fonts/MarkaziText-VariableFont_wght.ttf') format('truetype');
  }

.Blog{
    background-color: #ffffff;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 190px);
}

.post-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 7%;
  margin-right: 7%;
  margin-bottom: 9%;
  gap: 20px;
}

.post-card {
  width: 450px;
  height: 400px;
  display: block;
  overflow: hidden;
  text-decoration: none;
  border-radius: 8px;
  box-shadow: 0px 2px 8px #00000040;
  transition: transform 0.3s;
  flex: 1 1 325px;
  max-width: 500px;
}

.post-card:hover {
  transform: scale(1.05);
}

.post-image {
  width: 100%;
  height: 65%;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.post-details {
  padding: 15px;
}

.post-date {
  font-size: 14px;
  color: #888;
}

.post-title {
  font-size: 22px;
  color: #333;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 0px;
}
.post-card h2.post-title {
  padding-top: 10px; 
  margin-top: 0;
}

.post-author {
  display: flex;
  align-items: center;
}

.author-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.author-name {
  font-size: 14px;
  color: #555;
}
